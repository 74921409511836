<template>
  <div class="news">
    <div class="aft_banner">
      <img :src="$host + banner.litpic" alt="" />
      <!-- <img :src="$host + banner.litpicm" alt="" /> -->
    </div>
    <div class="aft_title p80">
      <div class="box">
        <p>{{ new_cate.englist_name }}</p>
        <div>{{ new_cate.typename }}</div>
      </div>
    </div>

    <div class="newsList">
      <div class="box m160">
        <div class="list" v-for="(item, index) in newsTopList" :key="index">
          <router-link :to="'/news/newsInfo?id=' + item.aid">
            <div class="flexBetween">
              <div class="boxLeft">
                <p><img :src="$host + item.img" alt="" /></p>
              </div>
              <div class="boxRight">
                <div class="content1 ellipsis">
                  {{ item.title }}
                </div>
                <div class="content2">
                  {{ (item.add_time * 1000) | dateFmt("YYYY-MM-DD") }}
                </div>
                <div class="content3 ellipsis3">
                  {{ item.desc }}
                </div>
                <div class="content4">
                  <p>
                    <svg
                      t="1655284142230"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="2370"
                      data-spm-anchor-id="a313x.7781069.0.i6"
                      width="14"
                      height="14"
                    >
                      <path
                        d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                        p-id="2371"
                        fill="#ffffff"
                      ></path>
                    </svg>
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="newsList1">
      <div class="box m160">
        <div class="list" v-for="(item, index) in new_list" :key="index">
          <router-link :to="'/news/newsInfo?id=' + item.aid">
            <div class="flexBetween">
              <div class="boxLeft flexStart">
                <div>{{ (item.add_time * 1000) | dateFmt("DD") }}</div>
                <p>{{ (item.add_time * 1000) | dateFmt("YYYY.MM") }}</p>
              </div>
              <div class="boxCenter">
                {{ item.title }}
              </div>
              <div class="boxRight flexEnd">
                <p>
                  <svg
                    t="1655284142230"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2370"
                    data-spm-anchor-id="a313x.7781069.0.i6"
                    width="14"
                    height="14"
                  >
                    <path
                      d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                      p-id="2371"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="aft_fengye" v-show="isOut">
      <div class="box flexCenter">
        <div class="btn prev" @click="PagePrev()">
          <a
            ><p>
              <svg
                t="1655284142230"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2370"
                data-spm-anchor-id="a313x.7781069.0.i6"
                width="14"
                height="14"
              >
                <path
                  d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                  p-id="2371"
                  fill="#ffffff"
                ></path>
              </svg></p
          ></a>
        </div>

        <div class="page_ flexCenter">
          <!-- <a href="" class="active"><span>01</span></a> -->

          <a
            v-for="(item, index) in last_page"
            :key="index"
            :class="{ active: PageIndex == index }"
            @click="changeHandler(index)"
          >
            <span v-if="index < 9">0{{ index + 1 }}</span>
            <span v-else>{{ index + 1 }}</span>
          </a>
        </div>
        <div class="btn next" @click="PageNext()">
          <a
            ><p>
              <svg
                t="1655284142230"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2370"
                data-spm-anchor-id="a313x.7781069.0.i6"
                width="14"
                height="14"
              >
                <path
                  d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                  p-id="2371"
                  fill="#ffffff"
                ></path>
              </svg></p
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { formatDate } from "../assets/js/data";

export default {
  name: "News",
  components: {},
  data() {
    return {
      isOut: false,
      isHeader: true,
      banner: {},
      new_cate: {},
      new_list: [],
      newsTopList: [],
      pages: 1,
      last_page: 1,
      PageIndex: 0,
    };
  },
  created() {
    this.$http
      .get(
        "api.php/Webinfo/news",
        {
          params: {
            page: this.pages,
          },
        },
        {
          emulateJSON: true,
        }
      )
      .then(
        function (res) {
          var news_data = JSON.parse(res.data);
          this.banner = news_data.banner;
          this.new_cate = news_data.newsList.cate;

          this.new_cate = news_data.newsList.cate;

          this.new_list = news_data.newsList.list;
          this.newsTopList = news_data.newsTopList;

          this.last_page = JSON.parse(res.data).last_page;
          this.isOut = true;

          // console.log(JSON.parse(res.data));
        },
        function (res) {
          console.log(res.status);
        }
      );
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  },
  methods: {
    PagePrev() {
      if (this.pages > 1) {
        this.PageIndex--;
        this.pages--;
        this.$http
          .get(
            "api.php/Webinfo/news",
            {
              params: {
                page: this.pages,
              },
            },
            {
              emulateJSON: true,
            }
          )
          .then(
            function (res) {
              var news_data = JSON.parse(res.data);
              this.new_list = news_data.newsList.list;
            },
            function (res) {
              console.log(res.status);
            }
          );
        window.scrollTo({
          top: 0,
        });
      }
    },
    PageNext() {
      if (this.pages < this.last_page) {
        this.PageIndex++;
        this.pages++;
        this.$http
          .get(
            "api.php/Webinfo/news",
            {
              params: {
                page: this.pages,
              },
            },
            {
              emulateJSON: true,
            }
          )
          .then(
            function (res) {
              var news_data = JSON.parse(res.data);
              this.new_list = news_data.newsList.list;
            },
            function (res) {
              console.log(res.status);
            }
          );

        window.scrollTo({
          top: 0,
        });
      }
    },
    changeHandler(i) {
      this.PageIndex = i;

      this.pages = i + 1;

      this.$http
        .get(
          "api.php/Webinfo/news",
          {
            params: {
              page: this.pages,
            },
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          function (res) {
            var news_data = JSON.parse(res.data);
            this.new_list = news_data.newsList.list;
          },
          function (res) {
            console.log(res.status);
          }
        );
      window.scrollTo({
        top: 0,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.newsList {
  margin-top: 0.3rem;
  .box {
    .list {
      padding: 0.45rem 0;
      border-bottom: 1px solid #ddd;
      a {
        display: block;
        .boxLeft {
          width: 50%;
          p {
            overflow: hidden;
            img {
              transition: 0.4s;
              width: 100%;
              vertical-align: bottom;
            }
          }
        }
        .boxRight {
          width: 50%;
          box-sizing: border-box;
          padding-left: 5%;
          .content1 {
            font-family: @albb_m;
            font-size: 0.32rem;
            color: #000;
            margin-top: 0.56rem;
            transition: 0.4s;
          }
          .content2 {
            font-family: @gilroy_r;
            font-size: 0.22rem;
            color: #999;
            margin-top: 0.12rem;
          }
          .content3 {
            font-size: 0.2rem;
            color: #666;
            line-height: 0.34rem;
            height: 1.02rem;
            margin-top: 0.56rem;
          }
          .content4 {
            margin-top: 0.6rem;
            p {
              width: 0.6rem;
              height: 0.6rem;
              line-height: 0.6rem;
              text-align: center;
              border-radius: 50%;
              background: @color;
              display: flex;
              svg {
                margin: auto;
              }
            }
          }
        }
      }
      &:hover {
        a {
          .boxLeft {
            p {
              img {
                transform: scale(1.1);
              }
            }
          }
          .boxRight {
            .content1 {
              color: @color;
            }
          }
        }
      }
    }
  }
}
.newsList1 {
  .box {
    .list {
      padding: 0.78rem 0;
      border-bottom: 1px solid #ddd;
      a {
        display: block;
        .boxLeft {
          width: 20%;
          font-family: @gilroy_r;
          line-height: 0.6rem;
          padding-top: 0.12rem;
          div {
            font-size: 0.52rem;
            color: #333;
            margin-right: 0.32rem;
          }
          p {
            font-size: 0.22rem;
            color: #999;
          }
        }
        .boxCenter {
          font-family: @albb_m;
          font-size: 0.26rem;
          color: #000;
          line-height: 0.4rem;
          width: 50%;
          text-align: justify;
          transition: 0.4s;
        }
        .boxRight {
          width: 30%;
          p {
            width: 0.6rem;
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            border-radius: 50%;
            background: @color;
            display: flex;
            svg {
              margin: auto;
            }
          }
        }
        &:hover {
          .boxCenter {
            color: @color;
          }
        }
      }
    }
  }
}
@media screen and(max-width:768px) {
  .aft_title .box div {
    font-size: 0.36rem;
  }
  .newsList .box .list a .boxLeft {
    width: 100%;
  }
  .newsList .box .list a .boxRight {
    width: 100%;

    padding-left: 0;
  }

  .newsList .box .list a .boxRight .content3 {
    height: auto;
  }
  .newsList1 .box .list a .boxLeft {
    display: none;
  }
  .newsList1 .box .list a .boxCenter {
    width: 74%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: justify;
    text-justify: distribute-all-lines;
  }
  .newsList1 .box .list a .boxRight {
    width: 16%;
  }
  .newsList1 .box .list {
    padding: 0.48rem 0;
  }
}
</style>
